import Swiper, { Navigation, Pagination } from 'swiper';

export default class {
    constructor({
        id,
    }) {
        const el = document.getElementById(id);
        const [prevEl, nextEl] = el.querySelectorAll('header > nav > button');
        const cont = el.querySelector('.swiper');

        new Swiper(cont, { // eslint-disable-line no-new
            modules: [Navigation, Pagination],
            slidesPerView: 'auto',
            navigation: {
                prevEl,
                nextEl,
            },
            spaceBetween: 24,
        });
    }
}
