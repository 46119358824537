export default class {
    constructor({
        id,
        fixedClass = 'is-fixed',
    }) {
        const el = document.getElementById(id);
        const header = el.querySelector('header');
        const observer = new IntersectionObserver(([entry]) => {
            el.classList.toggle(fixedClass, !entry.isIntersecting);
        });

        observer.observe(header);
    }
}
