import Player from '@vimeo/player';
import { events, emitEvent } from '../utilities/custom-events';
import { isIos, isMobile } from '../utilities/devices';

export default class {
    constructor({
        id,
        mobileAr = 1 / 1,
        desktopAr = 16 / 9,
        modalVideoHandle,
        leftClass = 'has-left',
        readyClass = 'is-ready',
    }) {
        const el = document.getElementById(id);
        const cont = el.parentElement;
        const [desktopBackgroundVideo, mobileBackgroundVideo] = el.querySelectorAll(`#${id} > iframe`);
        const desktopBackgroundPlayer = new Player(desktopBackgroundVideo);
        const mobileBackgroundPlayer = mobileBackgroundVideo
            ? new Player(mobileBackgroundVideo)
            : new Player(desktopBackgroundVideo);
        const playButton = el.querySelector('button');
        const modalVideo = el.querySelector(modalVideoHandle);
        const onMobile = isMobile() || isIos();

        let modalPlayer = null;

        function setVideoSize() {
            if (cont.offsetWidth / cont.offsetHeight > desktopAr) {
                desktopBackgroundVideo.style.width = `${cont.offsetWidth}px`;
                desktopBackgroundVideo.style.height = `${cont.offsetWidth / desktopAr}px`;
            } else {
                desktopBackgroundVideo.style.width = `${cont.offsetHeight * desktopAr}px`;
                desktopBackgroundVideo.style.height = `${cont.offsetHeight}px`;
            }

            if (cont.offsetWidth / cont.offsetHeight > mobileAr) {
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.width = `${cont.offsetWidth}px`;
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.height = `${cont.offsetWidth / mobileAr}px`;
            } else {
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.width = `${cont.offsetHeight * mobileAr}px`;
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.height = `${cont.offsetHeight}px`;
            }

            if (window.innerWidth < window.innerHeight && mobileBackgroundVideo) {
                desktopBackgroundVideo.style.display = 'none';
                desktopBackgroundPlayer.pause();
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.display = 'block';
                mobileBackgroundPlayer.play();
            } else {
                if (mobileBackgroundVideo) mobileBackgroundVideo.style.display = 'none';
                mobileBackgroundPlayer.pause();
                desktopBackgroundVideo.style.display = 'block';
                desktopBackgroundPlayer.play();
            }
        }

        if (!onMobile) {
            el.onmouseenter = () => { el.classList.remove(leftClass); };
            el.onmouseleave = () => { el.classList.add(leftClass); };
            el.onmousemove = ({ clientX, clientY }) => {
                const { offsetTop, offsetLeft } = el;
                const { top, left } = el.getBoundingClientRect();

                playButton.style.top = `${clientY - top - offsetTop}px`;
                playButton.style.left = `${clientX - left - offsetLeft}px`;
            };
        }
        window.addEventListener(events.closeView, e => {
            if (e.detail.view !== 'modal') return;

            if (modalPlayer) modalPlayer.pause();
            setVideoSize();
        });
        window.addEventListener('resize', setVideoSize);
        playButton.onclick = () => {
            emitEvent(events.loadView, {
                view: 'modal',
                markup: modalVideo.outerHTML,
                cb: modalContent => {
                    modalPlayer = new Player(modalContent.querySelector('iframe'));
                    modalPlayer.play();
                },
                pushState: false,
            });
        };
        desktopBackgroundPlayer.on('playing', () => { el.classList.add(readyClass); });
        desktopBackgroundPlayer.on('error', () => { el.classList.remove(readyClass); });
        mobileBackgroundPlayer.on('playing', () => { el.classList.add(readyClass); });
        mobileBackgroundPlayer.on('error', () => { el.classList.remove(readyClass); });

        setVideoSize();
    }
}
